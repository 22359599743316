import React, { FC } from 'react';
import { Seo } from '../components';
import { navigate, PageProps } from 'gatsby';
import { PageLayout, HomeCoverImg, Button } from '../components';
import config from '../../app-config.json';
import { Grid, Typography } from '@material-ui/core';
import * as s from '../style.pages/index.style';
import { siteMetadata } from '../../gatsby-config';

const PAGE_TITLE = config.pages.HOME.title;


//////////////////////////////////  component ////////////////////////////////
const Index: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} />
            <HomeCoverImg />
            <br /><br /><br />
            <s.BodyWrap  >
                <Typography gutterBottom variant='h2' ><b>Qardan Hasana – An Introduction</b></Typography>
                <Grid xs={12} container item justify='space-between' alignItems='flex-start' >
                    <Grid container item xs={12} sm={8} justify='flex-start' alignItems='flex-start' >
                        <Typography align='justify' >Qardan Hasana is the name given to that loan which is free and void of interest in any and every way.</Typography>
                        <br />
                        <Typography gutterBottom align='justify'>Responding to the directions given by His Holiness Syedna Mufaddal Saifuddin, his followers are determined to give up usurious transactions and carry on their business and fulfil their family needs in accordance with the tenets of Dawat-e-Hadiyah. To facilitate them the 53rd al-Dai al-Mutlaq is strengthening and expanding the religious and charitable institutions which serve as nuclei in several countries, to propagate, foster and implement the concept of Qardan Hasana through easy accessibility of funds and to encourage business transactions that avoid credit. Syedna Mohammed Burhanuddin RA states: "<em> To improve the economic condition of Mumineen and to raise their standard of living it is essential to consider Qardan Hasana as an economic system and take barakat from it.</em>"</Typography>
                        <br />
                        <Typography gutterBottom align='justify'>For the conceptualizers and omanaa’ of the Qardan Hasana Scheme this is not simply a mechanism for the giving out and receiving of funds. The intent is also to leverage and promote the gift of giving, and increase the quality of selflessness within the community. </Typography>
                    </Grid>
                    <Grid container item justify='flex-end' alignItems='flex-start' xs={12} sm={4} >
                        <s.SyednaMuffaddalImg title='His Holiness Syedna Mufaddal Saifuddin' />
                    </Grid>
                </Grid>
                <br />

                <Typography align='justify' style={{ width: '97%' }} gutterBottom >Through a variety of schemes, i.e. <b>Husain, Taher and Mohammedi,</b> which allow very small to large participations, it has been made possible for any Mumin to participate in the activities of Qardan Hasana. The Qardan Hasana program is a platform to benefit and uplift individual members and households of Mumineen, be it through financial support, or through knowledge, technical knowhow, contacts and connections, so that the overall wisdom within the community multiplies and benefits generations to come.</Typography>

                <br /><br />
                <Grid xs={12} container item className='qardan-hasana-para'>
                    <s.QardanHasanaIllustration />
                </Grid>
                <br /><br />
                <Grid container justify='center' ><Button color="primary" size='large' onClick={() => { navigate(config.pages.ABOUT_US.path) }} >Learn More</Button></Grid>
                <br /><br /><br />

            </s.BodyWrap>
        </PageLayout>
    )
}

export default Index;
